import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import ResponsiveMediaQuery from '../ResponsiveMediaQuery';
import { AppTheme } from '../Theme';

export type TextProps = HelperProps & {
  size?: string;
  weight?: CSS.FontWeightProperty;
  color?: keyof AppTheme['colors'];
  textShadow?: CSS.TextShadowProperty;
  align?: CSS.TextAlignProperty;
  decoration?: CSS.TextDecorationProperty<200>;
  whiteSpace?: CSS.WhiteSpaceProperty;
  wordWrap?: CSS.WordWrapProperty;
  inline?: boolean;
  lineHeight?: CSS.LineHeightProperty<string>;
  letterSpacing?: CSS.LetterSpacingProperty<string>;
  numberOfLines?: number;
  height?: CSS.HeightProperty<string>;
  wordBreak?: CSS.WordBreakProperty;
  leftBar?: boolean | 'big';
};

export const TextCss = css<TextProps>`
  ${helpers}
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.paragraph)};
  ${(props) =>
    props.leftBar
      ? css`
          display: flex;
          margin-bottom: ${props.leftBar === 'big' ? `` : `16px`};

          ::before {
            min-width: 8px;
            height: ${props.leftBar === 'big' ? `66px` : `16px`};
            margin-right: ${props.leftBar === 'big' ? `24px` : `8px`};
            align-self: center;
            background: ${(props) => props.theme.colors.primary};
            content: '';
          }
        `
      : ''}
  ${(props) =>
    props.textShadow
      ? css`
          text-shadow: ${props.textShadow};
        `
      : ''}
  ${(props) =>
    props.weight
      ? css`
          font-weight: ${props.weight};
        `
      : ''}
  ${(props) =>
    props.align
      ? css`
          text-align: ${props.align};
        `
      : ''}
  ${(props) =>
    props.decoration
      ? css`
          text-decoration: ${props.decoration};
        `
      : ''}
  ${(props) =>
    props.whiteSpace
      ? css`
          white-space: ${props.whiteSpace};
        `
      : 'white-space: normal;'}
  ${(props) =>
    props.wordWrap
      ? css`
          word-wrap: ${props.whiteSpace};
        `
      : ''}
  ${(props) =>
    props.inline
      ? css`
          display: inline-block;
        `
      : ''}
  ${(props) =>
    props.letterSpacing
      ? css`
          letter-spacing: ${props.letterSpacing};
        `
      : ''}
  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : ''}
  ${(props) =>
    props.wordBreak
      ? css`
          word-break: ${props.wordBreak};
        `
      : ''}

  ${(props) =>
    props.numberOfLines
      ? css`
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${props.numberOfLines};
        `
      : ''}

  a {
    color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.paragraph)};
  }
`;

export default {
  /**
   * Large <H1 /> text component with:
   * - font-size: 60px
   * - line-height: 66px
   */
  LargeTitle: styled.h1<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 28px;
    line-height: 30px;

    ${ResponsiveMediaQuery('tablet')} {
      font-size: 38px;
      line-height: 38px;
    }

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 60px;
      line-height: 66px;
    }
  `,
  /**
   * Medium <H1 /> text component with:
   * - font-size: 48px
   * - line-height: 54px
   */
  MediumTitle: styled.h1<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 30px;
    line-height: 32px;

    ${ResponsiveMediaQuery('tablet')} {
      font-size: 30px;
      line-height: 32px;
    }

    ${ResponsiveMediaQuery('laptop')} {
      font-size: ${(props) => (props.size ? props.size : '48px')};
      line-height: 54px;
    }
  `,
  /**
   * SmallTitle <H1 /> text component with:
   * - font-size: 36px
   * - line-height: 40px
   */
  SmallTitle: styled.h1<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.5px;

    ${ResponsiveMediaQuery('tablet')} {
      font-size: ${(props) => (props.size ? props.size : '36px')};
      line-height: 40px;
    }
  `,
  /**
   * A <H2 /> text component with:
   * - font-size: 32px
   * - line-height: 38px
   */
  H2: styled.h2<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 28px;
    line-height: 30px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 32px;
      line-height: 38px;
    }
  `,
  /**
   * A <H3 /> text component with:
   * - font-size: 28px
   * - line-height: 38px
   */
  H3: styled.h3<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 22px;
    line-height: 38px;

    ${ResponsiveMediaQuery('tablet')} {
      font-size: 28px;
      line-height: 38px;
    }
  `,
  /**
   * A <H4 /> text component with:
   * - font-size: 24px
   * - line-height: 26px
   */
  H4: styled.h4<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 18px;
    letter-spacing: 0.5px;

    ${ResponsiveMediaQuery('tablet')} {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.7px;
    }

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: initial;
    }
  `,
  /**
   * A <H5 /> text component with:
   * - font-size: 20px
   * - line-height: 24px
   * - letter-spacing: 0.5px
   */
  H5: styled.h5<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 20px;
    }
  `,
  /**
   * A <H6 /> text component with:
   * - font-size: 17px
   * - line-height: 24px
   */
  H6: styled.h6<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: ${(props) => (props.size ? props.size : '13px')};
    line-height: 19px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: ${(props) => (props.size ? props.size : '15px')};
      line-height: 24px;
    }

    ${ResponsiveMediaQuery('desktop')} {
      font-size: ${(props) => (props.size ? props.size : '17px')};
    }
  `,
  /**
   * Paragraph <p /> text component with:
   * - font-size: 18px
   * - line-height: 32px
   * - letter-spacing: 0.2px
   */
  Paragraph: styled.p<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 16px;
    line-height: 24px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.2px;
    }
  `,
  /**
   * SmallParagraph <p /> text component with:
   * - font-size: 16px
   * - line-height: 24px
   * - letter-spacing: 0.5px
   */
  SmallParagraph: styled.p<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  /**
   * SmallerParagraph <p /> text component with:
   * - font-size: 14px
   * - line-height: 22px
   * - letter-spacing: 0.4px
   */
  SmallerParagraph: styled.p<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: ${(props) => (props.size ? props.size : '14px')};
    line-height: 22px;
    letter-spacing: 0.4px;
  `,
  /**
   * ListSmall <li /> text component with:
   * - font-size: 16px
   * - line-height: 24px
   * - letter-spacing: 0.5px
   */
  ListSmall: styled.li<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  /**
   * ListSmaller <li /> text component with:
   * - font-size: 13px
   * - line-height: 22px
   * - letter-spacing: 0.4px
   */
  ListSmaller: styled.li<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: ${(props) => (props.size ? props.size : '13px')};
    line-height: 22px;
    letter-spacing: 0.4px;

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 13px;
      line-height: 22px;
    }
  `,
  /**
   * Caption <div /> text component with:
   * - font-size: 12px
   * - line-height: 20px
   * - letter-spacing: 0.5px
   */
  Caption: styled.div<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: ${(props) => (props.size ? props.size : '12px')};
    line-height: 20px;
    letter-spacing: 0.5px;
  `,
  /**
   * Tiny <div /> text component with:
   * - font-size: 11px
   * - line-height: 18px
   * - letter-spacing: 0.5px
   */
  Tiny: styled.div<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: ${(props) => (props.size ? props.size : '11px')};
    line-height: 18px;
    letter-spacing: 0.5px;
  `,
  Label: styled.label<TextProps>`
    margin: 0;
    ${TextCss};
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;

    span {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    ${ResponsiveMediaQuery('laptop')} {
      font-size: 15px;
      line-height: 26px;

      span {
        font-size: 15px;
        line-height: 26px;
      }
    }
  `
};
