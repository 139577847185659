import { CLIENT_IDS } from '../../constraints';
import color from 'color';

export const screens = {
  mobile: 576,
  tablet: 768,
  laptop: 1025,
  desktop: 1440
};

export const spacing = {
  sp0: '0px',
  sp4: '4px',
  sp8: '8px',
  sp16: '16px',
  sp22: '22px',
  sp24: '24px',
  sp32: '32px',
  sp38: '38px',
  sp42: '42px',
  sp48: '48px',
  sp58: '58px',
  sp61: '61px',
  sp72: '72px',
  sp96: '96px',
  sp144: '144px',
  sp192: '192px',
  ['sp-4']: '-4px',
  ['sp-8']: '-8px',
  ['sp-16']: '-16px',
  ['sp-24']: '-24px',
  ['sp-32']: '-32px',
  ['sp-42']: '-42px',
  ['sp-48']: '-48px',
  ['sp-58']: '-58px',
  ['sp-61']: '-61px',
  ['sp-72']: '-72px',
  ['sp-96']: '-96px',
  ['sp-144']: '-144px',
  ['sp-192']: '-192px'
};

export type SpacingEnum = keyof typeof spacing;

export type AvailableThemes = 'exportConnect' | 'californiaConnect';

export type AppTheme = {
  name: AvailableThemes;
  spacing: typeof spacing;
  screens: typeof screens;
  colors: {
    primary: string;
    secondary: string;
    background: string;
    backgroundLight1: string;
    backgroundInverted: string;
    border: string;
    borderDark: string;
    borderLight1: string;
    borderLight2: string;
    paragraph: string;
    paragraphLight1: string;
    paragraphOnInverted: string;
    paragraphOnPrimary: string;
    success: string;
    error: string;
    light: string;
    dark: string;
    lightGray: string;
  };
};

const exportConnect: AppTheme = {
  name: 'exportConnect',
  spacing,
  screens,
  colors: {
    primary: '#00BF6F',
    secondary: '#2A3C47',
    background: '#FFFFFF',
    backgroundLight1: '#F4F5F5',
    backgroundInverted: '#000000',

    border: '#949DA2',
    borderDark: color('#949DA2').darken(0.4).hex(),
    borderLight1: color('#949DA2').lighten(0.4).hex(),
    borderLight2: color('#949DA2').lighten(0.55).hex(),

    paragraph: '#1D2930',
    paragraphLight1: '#606060',
    paragraphOnInverted: '#FFFFFF',
    paragraphOnPrimary: '#fff',

    success: '#00BF6F',
    error: 'red',

    light: '#fff',
    dark: '#000',
    lightGray: '#E2E2E2'
  }
};

const californiaConnect: AppTheme = {
  name: 'californiaConnect',
  spacing,
  screens,
  colors: {
    secondary: '#333F48',
    primary: '#FDB71E',
    background: '#FFFFFF',
    backgroundLight1: '#F4F5F5',
    backgroundInverted: '#000000',

    border: '#949DA2',
    borderDark: color('#949DA2').darken(0.4).hex(),
    borderLight1: color('#949DA2').lighten(0.4).hex(),
    borderLight2: color('#949DA2').lighten(0.55).hex(),

    paragraph: '#1D2930',
    paragraphLight1: '#606060',
    paragraphOnInverted: '#FFFFFF',
    paragraphOnPrimary: '#fff',

    success: '#025e38',
    error: 'red',

    light: '#fff',
    dark: '#000',
    lightGray: '#E2E2E2'
  }
};

export const themes: AppTheme[] = [exportConnect, californiaConnect];

export const getThemeByClientId = (clientId?: string) => {
  switch (clientId) {
    case CLIENT_IDS.CALIFORNIA_CONNECT:
      return californiaConnect;
    default:
      return exportConnect;
  }
};
