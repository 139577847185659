import styled, { css } from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import * as CSS from 'csstype';
import { SpacingEnum } from '../Theme';
import ResponsiveMediaQuery from '../ResponsiveMediaQuery';

export type FlexProps = HelperProps & {
  alignItems?: CSS.AlignItemsProperty;
  justifyContent?: CSS.JustifyContentProperty;
  alignSelf?: CSS.AlignSelfProperty;
  justifySelf?: CSS.JustifySelfProperty;
  flexWrap?: CSS.FlexWrapProperty;
  vertical?: boolean;
  gap?: SpacingEnum | boolean;
  xsmallMobile?: boolean;
  mobile?: boolean;
  tablet?: boolean;
  laptop?: boolean;
  desktop?: boolean;
  grid?: boolean;
  full?: boolean;
  fullItems?: boolean;
  flex?: number;
  flexGrow?: boolean;
};

const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'initial')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'initial')};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'initial')};
  justify-self: ${(props) => (props.justifySelf ? props.justifySelf : 'initial')};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'initial')};
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  ${(props) => (props.full ? 'width: 100%; height: 100%;' : '')}

  ${(props) =>
    props.flexGrow !== undefined &&
    css`
      flex-grow: ${props.flexGrow === false ? '0' : '1'};
    `}

  /* For every element inside this component, but only the first nested */
  > * {
    ${(props) => (props.fullItems ? 'width: 100%; height: 100%;' : '')}
    /* If not the last one */
    &:not(:last-child) {
      ${(props) =>
        props.gap
          ? `${props.vertical ? 'margin-bottom' : 'margin-right'}: ${
              props.gap === true ? props.theme.spacing.sp16 : props.theme.spacing[props.gap]
            };`
          : ''}
    }
  }

  /* The following code defines if the flex should be vertical
  if the current screen is mobile/tablet/laptop */
  ${(props) =>
    props.xsmallMobile
      ? css`
          ${ResponsiveMediaQuery('mobile', true)} {
            flex-direction: column;
            > * {
              /* If not the last one */
              &:not(:last-child) {
                margin-right: initial;
                ${props.gap
                  ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sp16 : props.theme.spacing[props.gap]};`
                  : ''}
              }
            }
          }
        `
      : ''}

  ${(props) =>
    props.mobile
      ? css`
          ${ResponsiveMediaQuery('tablet', true)} {
            flex-direction: column;
            > * {
              /* If not the last one */
              &:not(:last-child) {
                margin-right: initial;
                ${props.gap
                  ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sp16 : props.theme.spacing[props.gap]};`
                  : ''}
              }
            }
          }
        `
      : ''}

${(props) =>
  props.tablet
    ? css`
        ${ResponsiveMediaQuery('laptop', true)} {
          flex-direction: column;
          > * {
            /* If not the last one */
            &:not(:last-child) {
              margin-right: initial;
              ${props.gap
                ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sp16 : props.theme.spacing[props.gap]};`
                : ''}
            }
          }
        }
      `
    : ''}

${(props) =>
  props.laptop
    ? css`
        ${ResponsiveMediaQuery('desktop', true)} {
          flex-direction: column;
          > * {
            /* If not the last one */
            &:not(:last-child) {
              margin-right: initial;
              ${props.gap
                ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sp16 : props.theme.spacing[props.gap]};`
                : ''}
            }
          }
        }
      `
    : ''}

${(props) =>
  props.grid
    ? `&:after {
      content: '';
      display: block;
      width: 32%;
      height: 1px;
    }`
    : ''}



  ${helpers};
`;

export default Flex;
